export const socialLinks = [
	{
		id: 1,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/dreamupstream',
	},
	{
		id: 2,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/gabrielius-ma%C5%BEeikis-611992216/',
	},
];
