<script>
import feather from "feather-icons";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      theme: "",
      images: [
        {
          src: require("@/assets/images/tech_stack/express.png"),
          alt: "Express",
        },
        { src: require("@/assets/images/tech_stack/html5.png"), alt: "HTML5" },
        {
          src: require("@/assets/images/tech_stack/mongo.png"),
          alt: "MongoDB",
        },
        { src: require("@/assets/images/tech_stack/nuxt.png"), alt: "Nuxt.js" },
        { src: require("@/assets/images/tech_stack/vue.png"), alt: "Vue.js" },
        {
          src: require("@/assets/images/tech_stack/sql.png"),
          alt: "SQL Database",
        },
        { src: require("@/assets/images/tech_stack/node.png"), alt: "Node.js" },
        // aws, azure, docker, git, figma, laravel, unity, wordpress, shopify, php
        { src: require("@/assets/images/tech_stack/aws.png"), alt: "AWS" },
        { src: require("@/assets/images/tech_stack/azure.png"), alt: "Azure" },
        {
          src: require("@/assets/images/tech_stack/docker.png"),
          alt: "Docker",
        },
        { src: require("@/assets/images/tech_stack/git.png"), alt: "Git" },
        { src: require("@/assets/images/tech_stack/figma.png"), alt: "Figma" },
        {
          src: require("@/assets/images/tech_stack/laravel.png"),
          alt: "Laravel",
        },
        { src: require("@/assets/images/tech_stack/unity.png"), alt: "Unity" },
        {
          src: require("@/assets/images/tech_stack/wordpress.png"),
          alt: "Wordpress",
        },
        {
          src: require("@/assets/images/tech_stack/shopify.png"),
          alt: "Shopify",
        },
        { src: require("@/assets/images/tech_stack/php.png"), alt: "PHP" },
      ],
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>

<template>
  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
  >
    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <h1
        class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
      >
        Hi, I am Gabrielius
      </h1>
      <p
        class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400"
      >
        A Full-Stack Developer & AI Enthusiast
      </p>
      <div class="flex justify-center sm:block">
        <a
          href="https://www.linkedin.com/in/gabrielius-ma%C5%BEeikis-611992216/"
          class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          <!-- linkedin img -->
          <img
            src="@/assets/images/linkedin_logo.png"
            alt="logo"
            class="w-6 h-6 sm:w-8 sm:h-8 mr-2"
          />
          <span class="text-sm sm:text-lg font-general-medium duration-100"
            >My Linkedin</span
          ></a
        >
      </div>
      <div class="tech-stack-carousel mt-8">
        <h2
          class="font-general-medium text-2xl text-center sm:text-left text-gray-600 mb-3 dark:text-primary-light"
        >
          My Tech Stack:
        </h2>
        <Carousel
          :itemsToShow="3.75"
          :wrapAround="true"
          :transition="500"
          autoplay="700"
          :class="theme === 'light' ? '' : 'bg-gray-50 border rounded-lg'"
        >
          <Slide v-for="slide in images" :key="slide">
            <div class="carousel__item">
              <img :src="slide.src" :alt="slide.alt" />
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>

    <!-- Banner right illustration -->
    <div class="w-full md:w-2/3 text-right float-right">
      <img
        v-if="theme === 'light'"
        src="@/assets/images/developer.svg"
        alt="Developer"
      />
      <img v-else src="@/assets/images/developer-dark.svg" alt="Developer" />
    </div>
  </section>
</template>

<style scoped>
.tech-stack-carousel img {
  max-width: 100%;
  display: block;
}

.carousel__slide {
  padding: 10px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.2);
}
</style>
