<script>
export default {
  props: ["project"],
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  data() {
    return {
      theme: "",
    };
  },
};
</script>

<template>
  <a
    :href="project.link"
    target="_blank"
    class="project-container rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
    aria-label="Single Project"
  >
    <div class="project-image">
      <img
        :src="
          project.imgDark
            ? theme === 'light'
              ? project.imgDark
              : project.imgLight
            : project.img
        "
        :alt="project.title"
        class="rounded-t-xl border-none"
      />
    </div>
    <div class="project-text">
      <p class="project-title">
        {{ project.title }}
      </p>
      <span class="project-category">
        {{ project.category }}
      </span>
    </div>
  </a>
</template>

<style scoped>
.project-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-text {
  margin-top: auto;
  text-align: center;
  padding: 16px;
}

.project-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.project-category {
  font-size: 1rem;
}
</style>
