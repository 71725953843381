// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    title: "Breach.gg",
    category: "Gamified Social Network",
    img: require("@/assets/images/projects/breach.png"),
    link: "https://breach.gg",
  },
  {
    id: 10,
    title: "Realco.lt Client System",
    category: "Web Application",
    imgDark: require("@/assets/images/projects/realco_dark.png"),
    imgLight: require("@/assets/images/projects/realco_light.png"),
    link: "https://realco.lt",
  },
  {
    id: 2,
    title: "Automio AI",
    category: "B2B AI bots agency",
    imgDark: require("@/assets/images/projects/automio_light.png"),
    imgLight: require("@/assets/images/projects/automio_dark.png"),
    link: "https://automio-f9777a8c02222b76820a50918d4fb04.webflow.io/",
  },
  {
    id: 3,
    title: "Baltic Linen Store",
    category: "E-Commerce store",
    img: require("@/assets/images/projects/baltic_linen.png"),
    link: "https://balticlinenstore.com/",
  },
  {
    id: 4,
    title: "Rhino Racing",
    category: "Website",
    imgDark: require("@/assets/images/projects/rr_light.png"),
    imgLight: require("@/assets/images/projects/rr_dark.png"),
    link: "https://rhinoracing.eu/",
  },
  {
    id: 5,
    title: "VideoHub",
    category: "Web Application",
    slug: "videohub",
    imgDark: require("@/assets/images/projects/videohub_light.png"),
    imgLight: require("@/assets/images/projects/videohub_dark.png"),
    images: [
      require("@/assets/images/projects/videohub_light.png"),
      require("@/assets/images/projects-inside/video_hub.png"),
      require("@/assets/images/projects-inside/video_hub1.png"),
      require("@/assets/images/projects-inside/video_hub2.png"),
      require("@/assets/images/projects-inside/video_hub3.png"),
    ],
    link: "/projects/videohub",
    github: "https://github.com/DreamUpstream/videoteka-frontend",
    date: "2023",
    techs: ["Vue.js", "Node.js", "Java Spring (for backend)", "Quasar"],
  },
  {
    id: 7,
    title: "Aniana.lt",
    category: "E-commerce store",
    img: require("@/assets/images/projects/aniana.png"),
    link: "https://aniana.lt/",
  },
  {
    id: 9,
    slug: "aruodas-flat-price-predictor",
    title: "Housing price predictor AI",
    category: "AI",
    img: require("@/assets/images/projects/aruodas.png"),
    link: "/projects/aruodas-flat-price-predictor",
    images: [
      require("@/assets/images/projects/aruodas.png"),

      require("@/assets/images/projects-inside/aruodas-gifas.gif"),
      require("@/assets/images/projects-inside/aruodas-1.png"),
      require("@/assets/images/projects-inside/aruodas-2.png"),
    ],
    date: "2023",
    techs: [
      "Python",
      "Machine Learning",
      "Pandas",
      "Numpy",
      "JavaScript",
      "Vue.js",
      "scrapers and other secret stuff :)",
    ],
    // github: "SECRET",
  },
  {
    id: 6,
    slug: "sneakerator-2",
    title: "Sneakerator - Stockx bot",
    category: "Web Application",
    img: require("@/assets/images/projects/sneakerator_logo.png"),
    link: "/projects/sneakerator-2",
    date: "2021",
    images: [
      require("@/assets/images/projects-inside/sneakerator_1.png"),
      require("@/assets/images/projects-inside/sneakerator_2.png"),
      require("@/assets/images/projects-inside/sneakerator_3.png"),
    ],
    techs: [
      "PHP",
      "Laravel",
      "Vue.js",
      "TailwindCSS",
      "MySQL",
      "Mailhog",
      "Docker",
      "Bootstrap",
    ],
    github: "https://github.com/DreamUpstream/Sneakerator2",
  },
  {
    id: 8,
    slug: "humor-faces",
    title: "Other projects",
    category:
      "More cool projects not listed here. Check them out in my GitHub!",
    img: require("@/assets/images/projects/github.png"),
    link: "https://github.com/DreamUpstream/",
  },
];

export default projects;
